<template>
  <div>
    <div class="white-box-half pt-2" v-if="data.active?.length > 0">
      <div
        class="row mx-2 my-1 align-items-center"
        v-for="row in data.active"
        :key="row.id"
      >
        <div class="col-2">
          <span class="badge badge-outline">{{ row.bucket }}</span>
        </div>
        <div class="col-2">
          <span class="badge badge-outline">{{ row.group }}</span>
        </div>
        <div class="col text-secondary" v-if="row.is_automatic">
          Automatic tracking from ActivityWatch
        </div>
        <div class="col" v-else>
          {{ row.name ?? "Not categorized" }}
        </div>
        <div class="col-1">
          <span class="bold-19" :key="timeKey">
            {{ formatTime(elapsedTime(row.started_at), true) }}
          </span>
        </div>
        <div class="col-auto" v-if="!row.is_automatic">
          <button
            class="btn btn-transparent"
            v-on:click="saveActiveTime(row.id)"
          >
            <BaseIcon name="plus-circle" />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="text-start custom-input m-2">
      <div class="row">
        <div class="col-12 mb-3 whitebg-input">
          <div class="divided-input">
            <v-select
              placeholder="What are you working on?"
              v-model="newItem"
              :options="tasks"
              taggable
              :create-option="(v) => createOption(v)"
              label="name"
              class="flex-2-1-0"
              append-to-body
            />
            <v-select
              v-if="newItem?.id === 0"
              placeholder="Select a bucket"
              v-model="selectedBucket"
              :options="data.buckets"
              label="name"
              :reduce="(c) => c.id"
              append-to-body
            />
            <v-select
              v-if="newItem?.id === 0 && selectedBucket > 0"
              placeholder="Select a group"
              v-model="selectedGroup"
              :options="groups"
              label="name"
              :reduce="(c) => c.id"
              append-to-body
            />
            <input
              type="time"
              v-if="manualAdd"
              v-model="duration"
              class="form-control duration-input"
            />
            <div class="custom-input-end" v-if="manualAdd">
              <button class="btn btn-link" v-on:click="manualAdd = false">
                <BaseIcon name="close-circle" />
                Close
              </button>
              <button class="btn btn-primary" v-on:click="saveTime(true)">
                Save <BaseIcon name="plus" />
              </button>
            </div>
            <div class="custom-input-end" v-else>
              <button class="btn btn-link" v-on:click="manualAdd = true">
                <BaseIcon name="plus" />
                Add manually
              </button>
              <button class="btn btn-primary" v-on:click="saveTime">
                Start <BaseIcon name="timer-start" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="white-box" v-for="week in weeks" :key="week">
      <div class="d-flex justify-content-evenly">
        <div
          v-for="day in week"
          :key="day"
          class="text-center time-day"
          :class="{ 'time-today': today === day.date }"
        >
          <div class="regular-24 text-secondary">{{ day.day }}</div>
          <div class="regular-14 text-secondary mb-2">
            {{ formatDate(day.date) }}
          </div>
          <div class="bold-24">
            {{ formatTime(data.duration[day.date] ?? 0) }}
          </div>
        </div>
      </div>
      <template v-for="day in Object.values(week).reverse()" :key="day">
        <div
          class="p-3"
          v-if="data.rows[day.date]?.length > 0 || today === day.date"
        >
          <h2 class="semibold-16 mb-3">{{ day.day }}</h2>
          <div class="row" v-for="row in data.rows[day.date]" :key="row">
            <div class="col-2">
              <span class="badge badge-outline">{{ row.bucket }}</span>
            </div>
            <div class="col-2">
              <span class="badge badge-outline">{{ row.group }}</span>
            </div>
            <div class="col">
              {{ row.name ?? "Automatic tracking from ActivityWatch" }}
            </div>
            <div class="col-1">
              <span class="bold-19">
                {{ formatTime(row.duration) }}
              </span>
            </div>
            <div class="col-auto">
              <button class="btn btn-transparent">
                <BaseIcon name="more" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
    {{ data }}
  </div>
</template>

<script>
import http from "@/modules/http";
import date from "../../../modules/date";
import BaseIcon from "../../../components/icons/BaseIcon.vue";

export default {
  name: "LocalTiming",
  components: { BaseIcon },
  data() {
    return {
      data: undefined,
      tasks: [],
      newItem: null,
      selectedBucket: null,
      groups: [],
      selectedGroup: null,
      duration: 0,
      timeKey: 0,
      manualAdd: false,
    };
  },
  computed: {
    today() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    weeks() {
      if (this.data === undefined) {
        return 0;
      }
      let keys = Object.keys(this.data.duration);
      let firstMonday = this.getNextMonday(keys[0]);
      let start = keys.findIndex((e) => e === firstMonday);
      let weeks = this.getWeeksBetweenDates(firstMonday, keys[keys.length - 1]);
      return Array.from({ length: weeks }, (_, index) =>
        this.getWeekDatesWithDayNames(keys[start + index * 7])
      ).reverse();
    },
  },
  mounted() {
    this.load();
    setInterval(() => this.timeKey++, 1010);
  },
  watch: {
    selectedBucket() {
      http
        .fetch("/work/buckets/" + this.selectedBucket + "/lists")
        .then((data) => {
          this.groups = data;
        });
    },
  },
  methods: {
    load() {
      http.fetch("/work/times").then((data) => {
        this.data = data;
      });
    },
    saveActiveTime(id) {
      http.fetch("/work/times/stop", { id: id }).then((data) => {
        this.data.active = [];
        this.data.duration = data.times.duration;
        this.data.rows = data.times.rows;
      });
    },
    saveTime(saveDuration = false) {
      let data = {
        work_task_group_id: this.selectedGroup,
        duration: saveDuration ? this.duration : 0,
      };
      if (this.newItem.id === 0) {
        data.name = this.newItem.name;
      } else {
        data.work_task_id = this.newItem.id;
      }
      http.fetch("/work/times", data).then((data) => {
        this.data.active = data.active;
      });
    },
    formatDate(val) {
      return date.format(val, false, false);
    },
    formatTime(seconds, secunds = false) {
      if (seconds >= 60) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        if (secunds) {
          const remainingSeconds = seconds % 60;
          return `${hours}:${minutes
            .toString()
            .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
        }
        return `${hours}:${minutes.toString().padStart(2, "0")}`;
      } else {
        return `${seconds} s`;
      }
    },
    getNextMonday(startDate) {
      const date = new Date(startDate);
      while (date.getDay() !== 1) {
        date.setDate(date.getDate() + 1);
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    getWeeksBetweenDates(startDate, endDate) {
      const date = new Date(startDate);
      const date2 = new Date(endDate);

      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date2.setHours(23);
      date2.setMinutes(59);
      date2.setSeconds(59);

      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(
        Math.abs((date.getTime() - date2.getTime()) / oneDay)
      );
      return Math.ceil(diffDays / 7);
    },
    elapsedTime(startDate) {
      const elapsedMilliseconds =
        new Date().getTime() - new Date(startDate).getTime();
      return Math.floor(elapsedMilliseconds / 1000);
    },
    createOption(d) {
      return {
        id: 0,
        name: d,
      };
    },
    getWeekDatesWithDayNames(startDate) {
      const dates = [];
      const currentDate = new Date(startDate);

      const firstDay = new Date(
        currentDate.setDate(
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
        )
      );

      const lastDay = new Date(firstDay);
      lastDay.setDate(firstDay.getDate() + 6);

      const dayNames = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      for (
        let date = new Date(firstDay);
        date <= lastDay;
        date.setDate(date.getDate() + 1)
      ) {
        const dayIndex = date.getDay();

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        dates.push({
          day: dayNames[dayIndex],
          date: `${year}-${month}-${day}`,
        });
      }

      return dates;
    },
  },
};
</script>
